<template>
  <div>
    <div class="form-avatar">
      <UpdateAvatar v-if="asyncData" :image="image" @crop-image="cropImage" />
    </div>
    <div class="form-avatar-skeleton" v-if="!asyncData">
      <ion-list class="ion-no-padding" lines="none">
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-skeleton-text class="skeleton-text" animated style="width: 100%"></ion-skeleton-text>
      </ion-list>
    </div>
    <div class="form-group">
      <ion-list class="ion-no-padding" lines="none">
        <ion-item
          :class="
            form.firstName.isError ? 'item-danger' : form.firstName.value ? 'item-primary' : 'item-medium'
          "
        >
          <div class="form-control">
            <ion-label
              :class="form.firstName.isError ? 'text-danger' : form.firstName.value ? 'text-primary' : ''"
              >{{ $t('first_name') }}</ion-label
            >
            <ion-input
              v-model="form.firstName.value"
              @ionInput="handleValidateForm('firstName')"
              class="input"
            />
          </div>
        </ion-item>
      </ion-list>
      <ion-text>
        <div v-if="form.firstName.isError" class="py-1 fs-12px text-danger form-error">
          {{
            form.firstName.value?.length === 0
              ? $t('first_name_cannot_be_empty')
              : $t('first_name_must_be_at_least_2_characters')
          }}
        </div>
      </ion-text>
    </div>
    <div class="form-group">
      <ion-list
        :class="['ion-no-padding', form.lastName.value ? 'item-primary' : 'item-medium']"
        lines="none"
      >
        <ion-item>
          <div class="form-control">
            <ion-label :class="form.lastName.value ? 'text-primary' : ''">{{ $t('last_name') }}</ion-label>
            <ion-input v-model="form.lastName.value" class="input" />
          </div>
        </ion-item>
      </ion-list>
    </div>
    <div class="form-group">
      <ion-list
        :class="['ion-no-padding', isValidDate(form.birthDate.value) ? 'item-primary' : 'item-medium']"
        lines="none"
      >
        <ion-item @click="onShowModalDateTime">
          <div class="form-control">
            <ion-label :class="form.birthDate.value ? 'text-primary' : ''">{{ $t('birth_date') }}</ion-label>
            <ion-text>{{
              isValidDate(form.birthDate.value) ? formatDateMonthYear(form.birthDate.value) : '-'
            }}</ion-text>
            <ModalDateTime
              v-if="isShowModalDateTime"
              :initialization_time="isValidDate(form.birthDate.value) ? form.birthDate.value : null"
              @handleSendTimeChange="handleSendTimeChange"
            ></ModalDateTime>
          </div>
        </ion-item>
      </ion-list>
    </div>
    <div class="form-group">
      <div class="form-group-radio">
        <ion-label :class="form.gender.value ? 'text-primary' : ''">{{ $t('gender') }}</ion-label>
        <ion-list lines="none" mode="md">
          <ion-radio-group v-model="form.gender.value">
            <div class="d-flex">
              <ion-item class="ion-no-border">
                <ion-label>{{ $t('male') }}</ion-label>
                <ion-radio slot="start" value="m"></ion-radio>
              </ion-item>

              <ion-item class="ion-no-border ml-6">
                <ion-label>{{ $t('female') }}</ion-label>
                <ion-radio slot="start" value="f"></ion-radio>
              </ion-item>
            </div>
          </ion-radio-group>
        </ion-list>
      </div>
    </div>

    <div class="form-group">
      <div
        :class="[
          'form-phone',
          form.mobile.isError ? 'item-danger' : form.mobile.value ? 'item-primary' : 'item-medium'
        ]"
      >
        <div class="phone-input">
          <ion-label :class="form.mobile.isError ? 'text-danger' : form.mobile.value ? 'text-primary' : ''">
            {{ $t('phone') }}
          </ion-label>
          <vue-tel-input
            v-model="form.mobile.value"
            mode="national"
            enabledCountryCode
            enabledFlags
            validCharactersOnly
            pattern="[0-9]*$"
            styleClasses="no-border	no-box-shadow mt-2"
            ref="phoneField"
            inputId="phoneField"
            disabledFetchingCountry
            :onlyCountries="getPreferedCountryCode()"
            :autoDefaultCountry="false"
            :defaultCountry="defaultCountry"
            :autoFormat="false"
            @on-input="onlyNumber"
            @keypress="blockDecimalInput"
            :inputOptions="{
              type: 'tel',
              maxlength: 16,
              placeholder: $t('mobile_number')
            }"
            @country-changed="countryChange"
          />
        </div>
      </div>
      <ion-text>
        <div v-if="form.mobile.isError" class="py-1 fs-12px text-danger form-error">
          {{ $t('phone_number_cannot_be_empty') }}
        </div>
      </ion-text>
    </div>
    <div class="form-group">
      <ion-list class="ion-no-padding" lines="none">
        <ion-item
          :class="form.email.isError ? 'item-danger' : form.email.value ? 'item-primary' : 'item-medium'"
        >
          <div class="form-control">
            <ion-label :class="form.email.isError ? 'text-danger' : form.email.value ? 'text-primary' : ''">{{
              $t('email')
            }}</ion-label>
            <ion-input v-model="form.email.value" @ionInput="handleValidateForm('email')" class="input" />
          </div>
        </ion-item>
      </ion-list>
      <ion-text>
        <div v-if="form.email.isError" class="py-1 fs-12px text-danger form-error">
          {{
            form.email.value?.length === 0
              ? $t('updateProfile.email_cannot_be_empty')
              : $t('this_field_must_be_a_valid_email')
          }}
        </div>
      </ion-text>
    </div>
    <div class="form-btn">
      <ion-button color="primary" mode="md" @click="handleSaveChanges">{{ $t('save_changes') }}</ion-button>
    </div>
    <ion-loading
      mode="ios"
      :is-open="isOpenRef"
      cssClass="my-custom-class"
      :message="`${$t('please_wait')}. . .`"
      @didDismiss="setOpen(false)"
    >
    </ion-loading>
    <ion-toast
      mode="ios"
      color="dark"
      :is-open="isOpenRefToast"
      :message="$t('saved_successfully')"
      :duration="2000"
      position="top"
      @didDismiss="setOpenToast(false)"
    >
    </ion-toast>
  </div>
</template>

<script>
import ModalDateTime from '@/components/DateTime/index.vue';
import { apolloClient } from '@/main';
import {
  findObjectByKey,
  getDefaultCountryCode,
  getPreferedCountryCode,
  getRegexEmail
} from '@/modules/b2b/services/libs/helper';
import { getCurrentUser, saleUpdateProfile } from '@/modules/sale/services/graphql';
import { ACTIONS as ACCOUNT_ACTIONS } from '@/modules/sale/store/account/actions';
import { useAlert } from '@/modules/shared/utils';
import { useDateFormatter } from '@/usecases/global';
import debounce from 'lodash.debounce';
import { nextTick, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { VueTelInput } from 'vue-tel-input';
import { createNamespacedHelpers } from 'vuex';

import 'vue-tel-input/dist/vue-tel-input.css';
import UpdateAvatar from './UpdateAvatar.vue';
const { mapActions } = createNamespacedHelpers('sale/account');

export default {
  components: {
    UpdateAvatar,
    VueTelInput,
    ModalDateTime
  },
  setup() {
    const { t } = useI18n();
    const { formatDateMonthYear, isValidDate } = useDateFormatter();

    const router = useRouter();
    const { createAlert } = useAlert();
    const isOpenRef = ref(false);
    const isOpenRefToast = ref(false);
    const isOpenRefToast1 = ref(false);
    const isShowModalDateTime = ref(false);
    const isOtpSuspend = ref(false);
    const isOpenLoadingref = ref(false);
    const isOpenModalDeleteRef = ref(false);
    const asyncData = ref(false);
    const oldPhoneNumber = ref(``);
    const oldImage = ref('');
    const image = ref(``);
    const countryCode = ref('');
    const defaultCountry = ref('');
    const tenantId = ref(``);
    const userId = ref(``);
    const phoneField = ref(null);
    const form = ref({
      firstName: {
        value: '',
        isError: false
      },
      mobile: {
        value: '',
        isError: false
      },
      email: {
        value: '',
        isError: false
      },
      lastName: {
        value: ''
      },
      gender: {
        value: ''
      },
      birthDate: {
        value: ''
      },
      tenant_id: {
        value: ''
      }
    });
    const setOpen = async (state) => (isOpenRef.value = state);
    const setOpenToast = async (state) => (isOpenRefToast.value = state);
    const setOpenToast1 = async (state) => (isOpenRefToast1.value = state);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const setOpenModalDelete = (state) => (isOpenModalDeleteRef.value = state);
    const fetchLoggedInUser = async () => {
      try {
        const { data } = await apolloClient.query({
          query: getCurrentUser
        });
        const profile = data.me;
        tenantId.value = profile.tenant.id;
        userId.value = profile.id;
        image.value = profile.image || null;
        form.value.firstName.value = profile.first_name || '-';
        form.value.lastName.value = profile.last_name || '-';
        form.value.gender.value = profile.gender || 'o';
        form.value.mobile.value = profile.mobile || '-';
        form.value.birthDate.value = profile.birth_date || '-';
        form.value.tenant_id.value = profile.tenant.id;
        form.value.email.value = profile.email || '-';
        asyncData.value = true;
        oldPhoneNumber.value = form.value.mobile.value || '-';
        const fullPhoneNumber = form.value.mobile.value;
        form.value.mobile.value =
          fullPhoneNumber.indexOf(' ') > -1
            ? fullPhoneNumber.split(' ')[1]
            : fullPhoneNumber.length > 8
            ? fullPhoneNumber.substring(2, fullPhoneNumber.length)
            : fullPhoneNumber;
        nextTick(() => {
          splitPhoneAndCountryCode(fullPhoneNumber, form.value.mobile.value);
        });
      } catch (e) {
        createAlert(t('errorTitle'), e.message);
      }
    };
    const splitPhoneAndCountryCode = (fullPhoneNumber, phone) => {
      countryCode.value =
        fullPhoneNumber.indexOf(' ') > -1
          ? fullPhoneNumber.split(' ')[0]
          : fullPhoneNumber.length > 8
          ? fullPhoneNumber.substring(0, 2)
          : getDefaultCountryCode();
      const country = findObjectByKey(phoneField.value.allCountries, 'dialCode', countryCode.value);
      defaultCountry.value = country ? country.iso2 : null;
      phoneField.value.choose(defaultCountry.value);
      form.value.mobile.value = phone;
    };
    const countryChange = (val) => {
      defaultCountry.value = val.iso2;
      countryCode.value = val.dialCode || getDefaultCountryCode();
    };
    const onShowModalDateTime = () => {
      isShowModalDateTime.value = false;
      nextTick(() => {
        isShowModalDateTime.value = true;
      });
    };
    const cropImage = (cropImg) => {
      image.value = cropImg;
      oldImage.value = cropImg;
    };
    const onlyNumber = (val) => {
      const input = val
        .toString()
        .replace(/^0+|^,|^\.+/g, '')
        .replace(/[^\d.,]/g, '');
      form.value.mobile.value = input.replace(/[.,]/g, '');
    };
    const blockDecimalInput = (event) => {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    };
    onMounted(() => {
      fetchLoggedInUser();
    });
    return {
      isOpenModal: ref(false),
      getPreferedCountryCode,
      formatDateMonthYear,
      isValidDate,
      isOpenRef,
      setOpen,
      isOpenRefToast,
      setOpenToast,
      isOpenRefToast1,
      setOpenToast1,
      form,
      isShowModalDateTime,
      onShowModalDateTime,
      isOtpSuspend,
      isOpenModalDeleteRef,
      setOpenModalDelete,
      isOpenLoadingref,
      setOpenLoading,
      router,
      defaultCountry,
      countryCode,
      asyncData,
      userId,
      oldPhoneNumber,
      oldImage,
      image,
      tenantId,
      countryChange,
      phoneField,
      createAlert,
      cropImage,
      onlyNumber,
      blockDecimalInput
    };
  },
  methods: {
    ...mapActions([ACCOUNT_ACTIONS.GET_CURRENT_USER]),
    validateForm(params) {
      try {
        if (params === 'firstName') {
          const lengthFirstName = this.form.firstName.value?.length || 0;
          this.form.firstName.isError = lengthFirstName === 0 || lengthFirstName < 2;
        } else if (params === 'phone') {
          this.form.mobile.isError = this.form.mobile.value?.length === 0;
        } else {
          const emailValue = this.form.email?.value;
          if (!emailValue || !getRegexEmail().test(emailValue)) {
            this.form.email.isError = true;
          } else {
            this.form.email.isError = false;
          }
        }
      } catch (e) {
        this.createAlert(this.$t('errorTitle'), e);
      }
    },
    handleValidateForm: debounce(async function (params) {
      this.validateForm(params);
    }, 500),
    handleSaveChanges: debounce(async function () {
      try {
        await this.setOpen(true);
        const params = ['firstName', 'phone', 'email'];
        for (const value of params) {
          this.validateForm(value);
        }
        let isUpdate = true;
        for (let i in this.form) {
          if (this.form[i].isError === true) {
            isUpdate = false;
            break;
          }
        }
        if (isUpdate) {
          const items = {
            firstName: this.form.firstName.value,
            lastName: this.form.lastName.value,
            gender: this.form.gender.value,
            email: this.form.email.value,
            birthDate: this.form.birthDate.value,
            mobile: `${this.countryCode + this.form.mobile.value}`,
            ...(this.oldImage ? { image: this.image } : {})
          };
          const { data } = await apolloClient.query({
            query: saleUpdateProfile,
            variables: items
          });
          await this[ACCOUNT_ACTIONS.GET_CURRENT_USER]();

          await this.setOpenToast(true);
          setTimeout(() => {
            if (data.saleUpdateProfile) {
              this.$router.push({
                path: '/sale/account',
                query: { isRenderProfile: true }
              });
            } else {
              this.$router.push('/sale/account');
            }
          }, 500);
        }
      } catch (error) {
        this.createAlert(this.$t('errorTitle'), error.message);
      } finally {
        await this.setOpen(false);
      }
    }, 500),
    handleCloseModal() {
      this.isOpenModal = false;
    },
    handleErrorEmit(message) {
      this.createAlert(this.$t('errorTitle'), message);
    },
    handleOpenLoading(params) {
      this.setOpen(params);
    },
    async handleSuccessVerify() {
      this.isOpenModal = false;
      await this.setOpenToast(true);
      this.handleGetConnectPerson();
    },
    handleSendTimeChange(e) {
      this.form.birthDate.value = String(e);
    }
  },
  watch: {
    'form.mobile.value': debounce(function () {
      this.validateForm('phone');
    }, 500)
  }
};
</script>

<style src="../styles/UpdateProfile.scss" lang="scss" scoped></style>
